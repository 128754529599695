import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/src/templates/PageLocal.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const BrandProfileComponent = makeShortcode("BrandProfileComponent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "sales-execution-mobilesem"
    }}>{`Sales Execution Mobile(SEM)`}</h1>
    <p><strong parentName="p"><em parentName="strong">{`‘Right Place + Right Action = Maximum Impact’`}</em></strong></p>
    <h2 {...{
      "id": "what-is-sem-about"
    }}>{`What is SEM about?`}</h2>
    <p>{`Sales Execution Mobile (SEM) is a digital tool that enables our Sales Force with uniquely developed features to make smarter and faster decisions to supported by actions and insights.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Build digital & commercial capability`}</strong></p>
        <p parentName="li">{`  Enabling a clear way of working to execute visits consistently and leverage digital tools to continuously improve sales capability and maximize impact during the visit`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Reducing cost to serve`}</strong></p>
        <p parentName="li">{`  Leverage on data to automate visit planning and ensure the optimal routing to focus on the outlets with the highest impact.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`UCE Collaboration`}</strong></p>
        <p parentName="li">{`  By connecting to global commerce ecosystems, we transform the data into insights to identify the next best action to drive sales volume and customer growth`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`User / Customer- Centric innovation`}</strong></p>
        <p parentName="li">{`  Together with our OpCos, we design, develop and test new features to ensure we build features with the highest value for our users.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Customer 360`}</strong></p>
        <p parentName="li">{`  Holistic customer visibility to activate our UCE touch points by integrating relevant customer data.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "mission-of-sem"
    }}>{`Mission of SEM`}</h2>
    <p>{`Accelerate our Sales Reps journey to Business Advisors leveraging on capabilities & data driven recommendations to drive customer growth.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Be a true Sales Partner to our customers`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Connect digitally and globally`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Do Less Admin Work`}</strong></li>
    </ul>
    <h3 {...{
      "id": "our-value-drivers"
    }}>{`Our Value Drivers`}</h3>
    <ul>
      <li parentName="ul">{`Customer satisfaction (NPS)`}</li>
      <li parentName="ul">{`Increase employee satisfaction`}</li>
      <li parentName="ul">{`Reduce travel and admin time`}</li>
      <li parentName="ul">{`Increase profitable volume per outlet`}</li>
      <li parentName="ul">{`Sustainable customer growth`}</li>
    </ul>
    <h2 {...{
      "id": "purpose-of-sem"
    }}>{`Purpose of SEM`}</h2>
    <h3 {...{
      "id": "employee-success"
    }}>{`Employee Success`}</h3>
    <ul>
      <li parentName="ul">{`Empower Sales Reps by capability training, nurture talents and increase employee satisfaction.`}</li>
      <li parentName="ul">{`Professionalize partnership by providing critical insights and data.`}</li>
    </ul>
    <h3 {...{
      "id": "customer-success"
    }}>{`Customer success`}</h3>
    <ul>
      <li parentName="ul">{`Increase customer success and satisfaction`}</li>
      <li parentName="ul">{`Build customer intimacy by non-operational time spent with customer.`}</li>
    </ul>
    <h3 {...{
      "id": "heineken-success"
    }}>{`Heineken success`}</h3>
    <ul>
      <li parentName="ul">{`Enable best connected brewer strategy by data, insights and actions`}</li>
      <li parentName="ul">{`Drive acquisition of high value customer`}</li>
      <li parentName="ul">{`Reduce cost to serve by activating touch point strategy`}</li>
    </ul>
    <h2 {...{
      "id": "sem-components"
    }}>{`SEM Components`}</h2>
    <p>{`SEM consists of different tools. Together, they create endless possibilities.`}</p>
    <BrandProfileComponent brandProfiles={[{
      image: require('/src/pages/articles/images/intro-to-sem/semlogo.png').default,
      label: "SEM App (offline)",
      description: "The front-end, used by sales professionals daily",
      link: "/"
    }, {
      image: require('/src/pages/articles/images/intro-to-sem/semgologo.png').default,
      label: "SEM Go Mobile",
      description: "The front-end on mobile applications, used by sales professionals daily",
      link: "/"
    }, {
      image: require('/src/pages/articles/images/intro-to-sem/comapplogo.png').default,
      label: "Companion app",
      description: "For sales reps to make and suggest data changes.",
      link: "/"
    }, {
      image: require('/src/pages/articles/images/intro-to-sem/hunterapp.png').default,
      label: "Hunter App",
      description: "A mobile app that will help Sales Representatives identify nearby Prospects easier.",
      link: "/"
    }, {
      image: require('/src/pages/articles/images/intro-to-sem/d365logo.png').default,
      label: "CRM-Dynamics 365",
      description: "The back-end, control panel used by key users daily",
      link: "/"
    }, {
      image: require('/src/pages/articles/images/intro-to-sem/powerbilogo.png').default,
      label: "Reporting in Power BI",
      description: "SEM captures a lot of data that is crucial to daily sales operations – analyse it in Power BI",
      link: "/"
    }, {
      image: require('/src/pages/articles/images/intro-to-sem/powerappslogo.png').default,
      label: "PowerApps",
      description: "Still in experimental phase, but open to development of and integration with PowerApps",
      link: "/"
    }]} mdxType="BrandProfileComponent" />
    <h2 {...{
      "id": "features--sem-pro"
    }}>{`Features – SEM PRO`}</h2>
    <p>{`Our features support the Sales Force through-out their day, from preparing to completing a visit.`}</p>
    <h3 {...{
      "id": "preparation"
    }}>{`Preparation`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Visit Generation/ Dynamic Journey Planning`}</strong></p>
        <p parentName="li">{`  Centralize, automate and optimize visit generation for all active sales reps.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Maps`}</strong></p>
        <p parentName="li">{`  Visualize your territory and easily identity outlets for ad-hoc visits and sell-ins.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Sales Folder`}</strong></p>
        <p parentName="li">{`  Trade Marketing-approved information at your fingertips, tools and guidelines to support sales arguments.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Contract Management`}</strong></p>
        <p parentName="li">{`  Build stronger relationships and capitalize on sales opportunities through contract Management.`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "execution"
    }}>{`Execution`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Customer 360`}</strong></p>
        <p parentName="li">{`  Complete overview of customers data (Financial, segmentation, performance, orders, NPS, outstanding RQC)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`GPS Tracking`}</strong></p>
        <p parentName="li">{`  Ensure route compliance and improve outlet location information through GPS.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Outlet Execution Standards`}</strong></p>
        <p parentName="li">{`  Track PICOS and improve every outlet after every visit.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Asset Management`}</strong></p>
        <p parentName="li">{`  Higher ROI and lower CAPEX by having the right equipment, compliant at the right outlets and brands.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Product Quality`}</strong></p>
        <p parentName="li">{`  Track quality and freshness of SKUs efficiently as one of the revenue drivers and part of PICOS.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Assortments`}</strong></p>
        <p parentName="li">{`  Macro-control targeting, standard-setting of product groups.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Price Compliance`}</strong></p>
        <p parentName="li">{`  Effectively compare actual retail prices during Outlet Execution Check to drive Affordability.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Activations`}</strong></p>
        <p parentName="li">{`  Maintain, track, sell-in and evaluate running promotions​.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Targeted Sales Activities`}</strong></p>
        <p parentName="li">{`  Real-time customer insights that support tailored sales offering.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Order Taking`}</strong></p>
        <p parentName="li">{`  Increase top-line growth immediately by selling in relevant products at the outlet`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Surveys`}</strong></p>
        <p parentName="li">{`  Grow the understanding of your customer and get direct feedback.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`User Satisfaction`}</strong></p>
        <p parentName="li">{`  Directly provide feedback in app to either local support team or global team on any improvements you can think of.`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "complete-visit"
    }}>{`Complete Visit`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`PowerBI Reporting`}</strong></p>
        <p parentName="li">{`  Capitalize on data-driven insights for commercial decision making.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Embedded Reports`}</strong></p>
        <p parentName="li">{`  Enable salesforce performance on key KPIs such as PICOS and Visits.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Volume reporting`}</strong></p>
        <p parentName="li">{`  Identify actionable trends per outlet and engage your customer by visualizing progress.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Coaching`}</strong></p>
        <p parentName="li">{`  Enable continuous improvement in your sales force by structured coaching on the job.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Auditing`}</strong></p>
        <p parentName="li">{`  Effectively back-check sales rep visits to ensure data quality and customer satisfaction.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      